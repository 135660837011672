@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';































@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
.Invoicing_root {
  height: $page-container-height;
}
